import React from "react";
import teamJ from "../assets/bene.jpg";
// import team1 from "../assets/flo.jpg";
import team1 from "../assets/flo2.jpeg";
import { Link } from "react-router-dom";
import bureau from "../assets/cabinet.png";
import { PRIMARY_TXT, PRIMARY_BCK } from "../constants/colors";

export const Team = () => (
  <div
    className="w3-container w3-margin-bottom"
    style={
      {
        // marginTop: "90px", // TODO take bar height
        // backgroundPosition: "center",
        // backgroundSize: "cover",
        // backgroundImage: `url(${bureau})`,
        // minHeight: "50%",
      }
    }
  >
    {/*<div className="w3-container" style={{ marginTop: "64px" }}>*/}
    {/*  <h2 className="w3-center">Nous prenons soin de vous !</h2>*/}
    {/*</div>*/}
    {/*w3-grayscale*/}
    <div
      style={{
        marginTop: 90,
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        flexWrap: "wrap",
        width: "100%",
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <div className="w3-hide-small w3-hide-medium" style={{ flex: 1 }} />

      <div style={{ flex: 1, margin: 10 }}>
        <Link
          to="/docteur"
          style={{
            color: "inherit",
            textDecoration: "inherit",
            height: "100%",
          }}
        >
          <div
            className="w3-card w3-center"
            style={{ backgroundColor: PRIMARY_BCK, height: "100%" }}
          >
            <img
              src={team1}
              alt="Docteur Florence GHION"
              style={{ width: "100%" }}
            />
            <div
              className="w3-container"
              style={{
                // color: "darkgreen"
                color: PRIMARY_TXT,
              }}
            >
              <h3>Docteur Florence GHION</h3>
              <p className="w3-opacity w3-large">Médecine Générale</p>
              <p className="w3-opacity w3-large">Acupuncture</p>

              <a
                className="w3-opacity w3-large"
                href="tel:+32474790169"
                style={{
                  color: "inherit",
                  textDecoration: "inherit",
                }}
              >
                0474/79.01.69
              </a>
              <button style={{marginTop:25, marginBottom:20, width:'100%', color:PRIMARY_TXT}}>
                Plus d'info
              </button>
            </div>
          </div>
        </Link>
      </div>
      <div style={{ flex: 1, margin: 10 }}>
        <Link
          to="/kine"
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <div
            className="w3-card w3-center"
            style={{ backgroundColor: PRIMARY_BCK, height: "100%" }}
          >
            <img src={teamJ} alt="Bénédicte GHION" style={{ width: "100%" }} />
            <div
              className="w3-container"
              style={{
                color: PRIMARY_TXT,
                // color: "darkgreen"
              }}
            >
              <h3>Bénédicte GHION</h3>
              <p className="w3-opacity w3-large">Kinésithérapie</p>
              <p
                className="w3-opacity w3-large"
                style={{ visibility: "hidden" }}
              >
                Post-natalité
              </p>
              <a
                className="w3-opacity w3-large"
                href="tel:+32478839256"
                style={{
                  color: "inherit",
                  textDecoration: "inherit",
                }}
              >
                0478/83.92.56
              </a>
              <button style={{marginTop:25, marginBottom:20, width:'100%', color:PRIMARY_TXT}}>
                Plus d'info
              </button>
            </div>
          </div>
        </Link>
      </div>
      <div className="w3-hide-small  w3-hide-medium" style={{ flex: 1 }} />
    </div>
    {/*<div className="w3-container w3-panel" style={{ marginTop: "24px" }}>*/}
    {/*  <h2 className="w3-center"><i>Nous prenons soin de vous !</i></h2>*/}
    {/*</div>*/}
    <div className="w3-panel w3-center">
      <p style={{ color: PRIMARY_BCK }}>
        <i className="fa fa-quote-right w3-xxlarge" />
        <br />
        <i className="w3-xlarge">Nous prenons soin de vous !</i>
      </p>
    </div>
  </div>
);
