import React from "react";
import { ServicesHeader } from "../../components/ServicesHeader";

export const Urgencies = () => (
  <div
    id={'top'}
    className="w3-content w3-container w3-padding-64"
    style={{ textAlign: "justify" }}
  >
    <ServicesHeader title={"Urgences"} />
    <h3>&gt; URGENCES VITALES :</h3> Appelez partout en Europe et gratuitement
    le 112 pour obtenir une aide immédiate des services d’incendie, une équipe
    médicale ou la police.
    <h3>&gt; URGENCES NON VITALES :</h3> Durant les heures de consultation, vous
    pouvez me joindre au <a href="tel:+32474790169">0474/79.01.69</a> pour
    intercaler un rendez-vous. Si je suis indisponible et que vous ne pouvez pas
    attendre, appelez SOS médecin 24h/24 et 7j/7 au
    <a href="tel:+3225130202"> 02/513.02.02</a> pour solliciter la visite d'un
    médecin de garde à votre domicile
    <h3>&gt; LE SOIR, WEEKEND et JOUR FERIES :</h3> Appelez le service de garde
    de votre commune :
    <ul>
      <li>
        Bruxelles (toutes les communes) : La Garde Bruxelloise:
        <a href="tel:+3222012222"> 02/ 201 22 22</a>
      </li>
      <li>
        Kraainem ou Wezembeek-Oppem: <a href="tel:+3290069004"> 0900/69.004</a>
      </li>
      <li>
        A Zaventem: <a href="tel:+3227250020"> 02/725.00.20</a>
      </li>
      <li>
        A Tervuren: <a href="tel:+3270222236"> 070/22.22.36</a>
      </li>
    </ul>
  </div>
);
