import React from "react";
import { ServicesHeader } from "../../components/ServicesHeader";

export const DMG = () => (
  <div
    id={'top'}
    className="w3-content w3-container w3-padding-64"
    style={{ textAlign: "justify" }}
  >
    <ServicesHeader title={"DMG"} />
    <p>
      Le Dossier médical global (DMG) est facultatif et rassemble après votre
      consentement toutes les informations médicales vous concernant. Il
      contribue à une meilleure connaissance de l’historique et de l’ensemble de
      vos soins de santé et ainsi à une prise en charge optimale de votre santé.
      Allergies, vaccinations, hospitalisations, radiographies, prises de sang…
      quels que soient les lieux où vous réalisez vos examens médicaux, toutes
      ces informations sont rassemblées dans votre DMG. Votre médecin de famille
      a ainsi une vision globale de tout ce qui touche à votre santé. Et, si
      vous le souhaitez, vous pouvez également demander à consulter votre
      dossier.
    </p>

    <p>
      Le DMG vous donne droit à un meilleur remboursement de vos soins de santé
      (une réduction de 30 % sur la partie qui est financièrement à votre
      charge). Il est 100% gratuit, vous pouvez demander à votre médecin de vous
      ouvrir un DMG à condition de vous présenter avec votre carte d'identité.
    </p>
  </div>
);
