import React from "react";

// import bureau from "../assets/bureau2.jpeg";
import bureau from "../assets/cabinet2.jpeg";

export const Header = () => (
  // <!-- Header with full-height image -->
  <header
    className="w3-display-container w3-grayscale-min"
    id="home"
    style={{
      marginTop: "50px", // TODO take bar height
      height: "460px", // TODO not computed !
      backgroundPosition: "center",
      backgroundSize: "cover",
      // backgroundImage: 'url("https://www.w3schools.com/w3images/mac.jpg")',
      // backgroundImage: `url("/assets/bureau.png")`,
      backgroundImage: `url(${bureau})`,
      // backgroundImage: bureau,
      minHeight: "50%",
      // backgroundImage: `url(../assets/bureau.png)`
    }}
  >
    <div className="w3-display-left w3-text-white" style={{ padding: "48px" }}>
      <span
        className="w3-jumbo w3-hide-small"
        style={{ textShadow: "0px 0px 12px rgba(150, 150, 150, 1)" }}
      >
        Docteur Florence&#160;GHION
      </span>
      <br />
      <span
        className="w3-xlarge w3-hide-large w3-hide-medium"
        style={{ textShadow: "0px 0px 12px rgba(150, 150, 150, 1)" }}
      >
        Docteur Florence&#160;GHION
      </span>
      <br />
      <span
        className="w3-large"
        style={{ textShadow: "0px 0px 12px rgba(150, 150, 150, 1)" }}
      >
        Médecine générale - Acupuncture
      </span>
      <p>
        <a
          href="https://www.doctoranytime.be/fr/d/general-practitioner/florence-ghion"
          target={"_blank"}
          rel={"noreferrer"}
          className="w3-button w3-white w3-padding-large w3-large w3-margin-top w3-opacity w3-hover-opacity-off"
        >
          Prendre RDV
        </a>
      </p>
    </div>
    {/*<div*/}
    {/*  className="w3-display-bottomleft w3-text-grey w3-large"*/}
    {/*  style={{ padding: "24px 48px" }}*/}
    {/*>*/}
    {/*  /!*  TODO no spacing correctly why ?*!/*/}
    {/*  <i className="fa fa-facebook-official w3-hover-opacity" />*/}
    {/*  <i className="fa fa-instagram w3-hover-opacity" />*/}
    {/*  <i className="fa fa-snapchat w3-hover-opacity" />*/}
    {/*  <i className="fa fa-pinterest-p w3-hover-opacity" />*/}
    {/*  <i className="fa fa-twitter w3-hover-opacity" />*/}
    {/*  <i className="fa fa-linkedin w3-hover-opacity" />*/}
    {/*</div>*/}
  </header>
);
