import React from "react";
import { NavBar } from "../components/NavBar";
import { KineInformations } from "./subpages/KineInfo";
import { ScrollToTopOnMount } from "../components/ScrollToTop";

export const HomeKine = () => (
  <>
    <ScrollToTopOnMount />
    <NavBar
      title={"Bénédicte GHION"}
      mainUrl={"/kine"}
      links={[
        { label: "Le cabinet", url: "/" },
        {
          label: "RDV",
          extUrl:
            "https://www.doctoranytime.be/d/kinesitherapeute/benedicte-ghion",
        },
        { label: "Docteur", url: "/docteur" }
      ]}
    />
    <KineInformations />
    <div style={{ textAlign: "center", color: "darkgrey", marginBottom: 20 }}>
      2021 - Webapp{" "}
      <a target="_blank" href="https://www.linkedin.com/in/ludovic-gustin/">
        {" "}
        by LG
      </a>
    </div>
  </>
);
