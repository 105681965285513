import React from "react";

export const AUrl = ({ url, brk = true }: { url: string; brk?: boolean }) => (
  <>
    {!brk && " "}
    <a
      href={url}
      target={"_blank"}
      rel={"noreferrer"}
      style={{
        display: "block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        verticalAlign: "top",
      }}
    >
      {url}
    </a>
    {brk && <br />}
  </>
);
