import React from "react";

export const Vision = () => (
  <div className="w3-container">
    <div id="vision" className="w3-row-padding w3-padding-64">
      <div className="w3-content">
        <div className="w3-auto">
          <h1>Notre vision</h1>
          <h5 className="w3-padding-32">
            Approche holistique
            <br />
            Dynamique familiale
            <br />
            Bien-être général
          </h5>
          <p className="w3-text-grey">
            Nous, Bénédicte et Florence, deux sœurs dans la vie, deux collègues
            au cabinet, travaillons dans un esprit de famille esprit de famille
            avec Geneviève, notre tante et Géraldine, notre cousine.
            Nous voulons que chaque patient trouve dans nos soins
            une approche qui lui convienne. Accueilli et bienvenu, nous essayons
            que chacun puisse se sentir écouté et soigné. Nouveaux-nés, enfants,
            adultes ou personnes plus âgées, nous adaptons nos traitements à
            chaque patient.
          </p>
          <p className="w3-text-grey">
            Comme le défini l’Organisation Mondiale de la Santé, nous avons une
            vision commune de la santé : un état de complet bien-être physique,
            mental et social, et pas seulement l’absence de maladie et
            d’infirmité.
          </p>
          <p className="w3-text-grey">
            Soyez les bienvenus, nous prenons soin de vous
          </p>
        </div>
      </div>
    </div>
  </div>
);
