import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export const ServicesHeader = ({ title }: { title: string }) => {
  // useEffect(() => { window.location.hash='#top'}, []);
  return (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <h1>{title}</h1>
    <Link to={"/docteur"}>
      <i className="fa fa-chevron-circle-left" style={{ marginRight: 10 }} />
      Retour
    </Link>
  </div>
)};
