import React from "react";

interface Props {
  title: string;
  description: string;
  calendar: string;
  meetingPoint: string;
}
export const ProjectLine = (props: Props) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <>
      <h4 onClick={() => setExpanded((p) => !p)}>
        <i
          className={expanded ? "fa fa-chevron-down" : "fa fa-chevron-right"}
          style={{ marginRight: 10 }}
        />
        {props.title}
      </h4>
      {expanded && (
        <>
          <ul>
            <li>{props.description}</li>
            <li>{props.calendar}</li>
            <li>{props.meetingPoint}</li>
          </ul>
        </>
      )}
    </>
  );
};
