import React from "react";
import { Link } from "react-router-dom";
import { PRIMARY_BCK } from "../constants/colors";

interface Props {
  title: string;
  iconName: string;
  path: string;
}

export const Card = ({ title, iconName, path }: Props) => (
  <Link
    to={path}
    className="w3-col l4 m6 w3-margin-bottom"
    style={{
      color: "inherit",
      textDecoration: "inherit",
    }}
  >
    <div
      className="w3-card"
      style={{
        padding: "25px",
        // backgroundColor:'#5E8DD0',
        // color:'#F49530'
        display: "flex",
        justifyContent: "space-between",
        color: "",
      }}
    >
      <i
        className={"fa " + iconName + " w3-margin-bottom w3-jumbo"}
        style={{
          // borderStyle:'solid',
          color: PRIMARY_BCK,
          alignSelf: "center",
        }}
      />
      <p
        className="w3-xlarge"
        style={
          {
            // borderStyle:'solid'
          }
        }
      >
        {title}
      </p>
    </div>
  </Link>
);

interface UrlProps {
  title: string;
  iconName: string;
  url: string;
}
export const UrlCard = ({ title, iconName, url }: UrlProps) => (
  <a
    href={url}
    target={"_blank"}
    rel={"noreferrer"}
    className="w3-col l4 m6 w3-margin-bottom"
    style={{
      color: "inherit",
      textDecoration: "inherit",
    }}
  >
    <div
      className="w3-card"
      style={{
        padding: "25px",
        // backgroundColor:'#5E8DD0',
        // color:'#F49530'
        display: "flex",
        justifyContent: "space-between",
        color: "",
      }}
    >
      <i
        className={"fa " + iconName + " w3-margin-bottom w3-jumbo"}
        style={{
          // borderStyle:'solid',
          color: PRIMARY_BCK,
          alignSelf: "center",
        }}
      />
      <p
        className="w3-xlarge"
        style={
          {
            // borderStyle:'solid'
          }
        }
      >
        {title}
      </p>
    </div>
  </a>
);
