import React from "react";
import { ServicesHeader } from "../../components/ServicesHeader";
import { AUrl } from "../../components/AUrl";

export const CovidInfo = () => (
  <div
    id={'top'}
    className="w3-content w3-container w3-padding-64"
    style={{ textAlign: "justify" }}
  >
    <ServicesHeader title={"Infos Covid-19"} />
    <AUrl url="https://www.info-coronavirus.be/" />
    <AUrl url="https://covid-19.sciensano.be/fr" />
    <AUrl url="https://testcovid.be/" />
    <AUrl url="https://diplomatie.belgium.be/fr/Services/voyager_a_letranger" />
    <h3>&gt; Quels sont les symptômes liés au COVID 19 ?</h3>
    Un cas possible de COVID-19 est une personne avec au moins un des symptômes
    majeurs suivants d'apparition aiguë, sans autre cause évidente: toux;
    dyspnée (difficulté respiratoire); douleur thoracique; perte de goût ou
    d’odorat OU au moins deux des symptômes mineurs suivants sans autre cause
    évidente , fièvre; douleurs musculaires; fatigue; rhinite; maux de gorge;
    maux de tête; anorexie; diarrhée aqueuse ; confusion aiguë; chute soudaine
    OU une aggravation de symptômes respiratoires chroniques (BPCO, asthme, toux
    chronique...), sans autre cause évidente.
    <h3>
      &gt; Vous êtes malade ou vous avez été en contact avec une personne testée
      positive :
    </h3>
    Restez chez vous. Appelez-moi et transmettez-moi vos coordonnées complètes
    (numéro de registre national, adresse postale, numéro de téléphone, date de
    naissance). On discutera des mesures à prendre en fonction de ce que vous me
    décrivez (quarantaine, isolement, traitement symptomatique, ..) et si
    nécessaire je vous enverrai une prescription pour vous faire tester dans un
    centre de prélèvement. Inscrivez-vous gratuitement sur le site
    <AUrl url="https://testcovid.be" brk={false} /> et rendez-vous dans le
    centre de prélèvement le plus proche. Veillez à respecter les mesures
    d'hygiène et listez les personnes avec lesquelles vous avez été en contact
    au cours de la semaine. Si vous avez été en contact avec quelqu’un de
    positif, il vous sera demandé de faire deux tests, le premier dès que vous
    êtes au courant de la positivité de l’autre personne, et le deuxième au 7ème
    jour. Vous sortez de quarantaine seulement après le deuxième test négatif.
    Le premier test négatif ne permet pas de lever la quarantaine. Pour les
    enfants de &lt; 6 ans, des règles supplémentaires s’appliquent pour le
    testing. Au vu des différentes mesures qui changent continuellement,
    appelez-moi et je vous guiderai dans vos démarches.
    <h3>&gt; Durée de la quarantaine</h3>
    Actuellement, la durée de la quarantaine s’élève à 10 jours après les
    premiers symptômes, ou après le dernier contact avec la personne positive.
    Ces réglementations changent continuellement, appelez-moi pour être certain
    de la durée de l’isolement.
    <h3>&gt; Ou retrouver les résultats ?</h3>
    Vous pouvez me joindre par SMS au 0474/79 01 69 pour les résultats de votre
    test 48h après avoir effectué votre test, ou vous rendre sur le site
    <AUrl url="https://www.masante.belgique.be" brk={false} /> pour visualiser
    vous-même les résultats. Il vous faudra vous munir d’un lecteur de carte ou
    de l'application Itsme.
    <h3>&gt; Que puis-je faire pour éviter de contaminer mes proches?</h3>
    Pour tous: respect des gestes barrières (port du masque, respect d'1.5m de
    distance, nettoyage régulier des mains) Vous êtes malade: mesures d'hygiènes
    indispensables :
    <br />
    <AUrl
      url="https://covid-19.sciensano.be/sites/default/files/Covid19/COVID-19_procedure_hygiene_case%20%26houshold_FR.pdf"
      brk={true}
    />
    <h3>&gt; Vous revenez d'une zone à risque:</h3>
    Complétez le PLF (
    <AUrl
      url="https://travel.info-coronavirus.be/fr/public-health-passenger-locator-form"
      brk={false}
    />
    ) dans les 48h de votre retour. En fonction du risque évalué par le
    questionnaire, vous recevrez un code pour vous faire tester.
    <h3>&gt; Vous partez en voyage</h3>
    Sachez que les voyages non essentiels sont à présent interdits. Avant votre
    voyage: remplissez le formulaire de déclaration sur l’honneur (
    <AUrl
      url="https://travel.info-coronavirus.be/fr/voyage-essentiel"
      brk={false}
    />
    ) et vérifiez les conseils appliqués dans le pays où vous comptez vous
    rendre (
    <AUrl
      url="https://diplomatie.belgium.be/fr/Services/voyager_a_letranger/conseils_par_destination"
      brk={false}
    />
    ) Certains pays imposent un résultat de test négatif comme condition
    d'entrée sur le territoire. Ce test est à vos frais et non remboursé par la
    mutuelle. Il ne faut pas de prescription médicale pour effectuer le test.
  </div>
);
