import React from "react";
import { Card, UrlCard } from "./Card";

export const Cards = () => (
  <div
    className="w3-container w3-margin-top"
    style={{ padding: "16px 16px" }}
    id="services"
  >
    <div
      className="w3-row-padding w3-center w3-stretch	"
      style={{ marginTop: "0px" }}
    >
      <Card
        title="Informations"
        path="/docteur/informations"
        iconName="fa-info-circle"
      />
      <UrlCard
        title="RDV"
        url="https://www.doctoranytime.be/fr/d/general-practitioner/florence-ghion"
        iconName="fa-calendar"
      />
      <Card
        title="Urgences"
        path="/docteur/urgences"
        iconName="fa-phone-square"
      />
      <Card
        title="Dossier Médical Global"
        path="/docteur/dmg"
        iconName="fa-folder-open-o"
      />
      <Card
        title="Sites Utiles"
        path="/docteur/liens-utiles"
        iconName="fa-external-link"
      />
      <Card
        title="Info Covid"
        path="/docteur/covid"
        iconName="fa-thermometer-full"
      />
    </div>
  </div>
);
