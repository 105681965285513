import React, { useEffect, useState } from "react";
import { ProjectLine } from "./ProjectLine";

const FL = ["titre: ", ", description: ", ", agenda: ", ", rdv: "];

interface Props {}
// https://spreadsheets.google.com/feeds/list/1jL0NRByIpaGZnSxEhZ8qt-k9twLRLlyZeWQ3FNkOb1k/omxooju/public/values?alt=json
export const CMSProjects = ({}: Props) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetch(
      "https://spreadsheets.google.com/feeds/list/1DCX1kUvDYFIln6C2Bb_5WyiTGoMIKr3h2pWAawKC_Zk/ohahl6y/public/basic?alt=json"
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data.feed.entry);
        setItems(data.feed.entry);
      })
      .catch((e) => console.warn(e))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div
      id={"projects"}
      className="w3-container"
      style={{
        textAlign: "justify",
      }}
    >
      <div id="team" className="w3-row-padding w3-light-grey w3-padding-64">
        <div className="w3-content">
          <h1>Nos projets</h1>
          <p className="w3-text-grey">
            Dès que la situation sanitaire le permet, nous vous proposerons
            plusieurs projets qui nous tiennent à coeur.
            <br />
            Nous adapterons notre page en temps voulu.
          </p>
          {loading && "Loading ..."}
          {!loading &&
            items &&
            items.map((item: any, index) => {
              const data = item.content.$t;
              const indexes = FL.map((f) => data.indexOf(f));

              return (
                <ProjectLine
                  title={data.substring(FL[0].length, indexes[1])}
                  description={data.substring(
                    indexes[1] + FL[1].length,
                    indexes[2]
                  )}
                  calendar={data.substring(
                    indexes[2] + FL[2].length,
                    indexes[3]
                  )}
                  meetingPoint={data.substring(indexes[3] + FL[3].length)}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
