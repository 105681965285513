import React, { useEffect } from "react";
import { ServicesHeader } from "../../components/ServicesHeader";
import { ScrollToTopOnMount } from "../../components/ScrollToTop";
import { Route } from "react-router";

export const GeneralInformations = () => {
  useEffect(() => { window.location.hash='#top'}, []);
  return (
  <div
    id={'top'}
    className="w3-content w3-container w3-padding-64"
    style={{ textAlign: "justify" }}
  >
    <ServicesHeader title={"Informations"} />
    <h3 >Mon premier rendez-vous</h3>
    <p>
      Chaque premier rendez-vous est un lieu de rencontre et d’échange. Cette
      consultation se prépare. Pensez à apporter votre carte d’identité ou celle
      de votre enfant. Pour les enfants, n’oubliez pas leur carnet de l’ONE et
      leur carnet de vaccination. Tous les documents que vous avez en votre
      possession concernant votre historique médical ainsi que la liste de vos
      traitements habituels sont importants, pensez-y. Si possible,
      munissez-vous d’une vignette de mutuelle qui sera toujours utile lors de
      prélèvements (prise de sang ou analyse d’urine, ..).
    </p>
    <h3>Ma pratique</h3>
    <p>
      Je pratique la <b>médecine générale</b>, c'est-à-dire prendre soin de vous
      à chaque étape de votre vie. Je prends en charge les nouveaux-nés,
      enfants, adolescents, adultes et personnes âgées. J’aime particulièrement
      cette variété de générations. Que ce soit pour une consultation unique,
      occasionnelle ou pour assurer votre suivi pour des maladies chroniques,
      pour la croissance des enfants, la vaccination de tous, j’assure des
      consultations au cabinet et en visite à domicile (si celles-ci sont dans
      un périmètre proche du cabinet).
    </p>
    <p>
      Je pratique également de l’<b>acupuncture</b>. L’acupuncture fait partie
      de la médecine traditionnelle chinoise, et consiste en une stimulation de
      zones précises de l'épiderme : les « points d’acupuncture ». La
      stimulation d’un point provoque la libération d'endorphines permettant de
      diminuer les douleurs et de procurer une sensation de bien-être.
      L’acupuncture agit également au niveau du système nerveux central et
      permet de bloquer la transmission de certaines informations sensitives
      négatives en provenance de la périphérie. Elle agit également sur le
      système neurovégétatif, donc l’état psychologique des patients, apportant
      un état d’esprit positif et paisible.
      <p>
        L’acupuncture permet de stimuler les capacités de défense de l’organisme
        et l’aide à harmoniser ses énergies. C’est une thérapie curative ou
        préventive.
      </p>
      Les deux médecines, occidentale et orientale, permettent de prendre soin
      de vous avec une vision holistique et personnalisée. Les deux sont
      complémentaires, mais non obligatoires. Selon vos envies, selon vos
      craintes, une approche personnalisée vous sera proposée.
    </p>
    <h3>Horaires de consultation</h3>
    <p>
      Les horaires de consultation sont variables. Je vous invite à&nbsp;
      <a
        href={
          "https://www.doctoranytime.be/fr/d/general-practitioner/florence-ghion"
        }
        target={"_blank"}
        rel={"noreferrer"}
      >
        prendre rendez-vous en ligne
      </a>
      . Si vous avez une urgence, n’hésitez pas à me joindre sur mon numéro de
      GSM au <a href="tel:+32474790169">0474/79.01.69</a>.
    </p>

    <h3>Honoraires</h3>
    <p>
      Je suis conventionnée et pratique les tarifs de l'Inami ainsi que le
      tiers-payant. J’accepte les paiements mobiles et le cash. Je n’ai pas de
      terminal bancontact.
    </p>
  </div>
)};
