import React from "react";
import team1 from "../assets/team-genevieve2.jpeg";
import geraldine from "../assets/geraldine.jpeg";

export const SousTeam = () => (
  <div className="w3-container" style={{ width: "100%" }}>
    <div id="team" className="w3-row-padding w3-light-grey w3-padding-64">
      <div className="w3-content">
        <h1>Notre équipe</h1>
        Nous travaillons en étroite collaboration avec :
        <div
          className="w3-container"
          style={{
            marginTop: "25px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            className="w3-hide-small w3-hide-medium"
            style={{ flex: 1, display: "flex", justifyContent: "center" }}
          >
            <img
              className="w3-circle w3-card w3-margin-bottom"
              src={geraldine}
              alt="Géraldine de Meeûs"
              style={{ width: "50%" }}
            />
          </div>
          <div style={{ flex: 1, alignSelf: "center" }}>
            <div className="w3-container w3-center">
              <img
                className="w3-circle w3-card w3-margin-bottom w3-hide-large"
                src={geraldine}
                alt="Geneviève van&nbsp;Caubergh"
                style={{ width: "50%" }}
              />
              <h3>Géraldine de&nbsp;Meeûs</h3>
              <p>Kinésithérapeute</p>
              <p>Consultation sur rendez-vous : 0488/44.38.92</p>
              <p>
                <a href='https://www.doctoranytime.be/d/kinesitherapeute/geraldine-de-meeus'>Rendez-vous en ligne</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* 2nd*/}
      <div className="w3-content">
        <div
          className="w3-container"
          style={{
            marginTop: "25px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ flex: 1, alignSelf: "center" }}>
            <div className="w3-container w3-center">
              <img
                className="w3-circle w3-card w3-margin-bottom w3-hide-large"
                src={team1}
                alt="Geneviève van&nbsp;Caubergh"
                style={{ width: "50%" }}
              />
              <h3>Geneviève van&nbsp;Caubergh</h3>
              <p>Kinésithérapeute</p>
              <p>Consultation sur rendez-vous : 0479/29.47.77</p>
            </div>
          </div>
          <div
            className="w3-hide-small w3-hide-medium"
            style={{ flex: 1, display: "flex", justifyContent: "center" }}
          >
            <img
              className="w3-circle w3-card w3-margin-bottom"
              src={team1}
              alt="Geneviève van Caubergh"
              style={{ width: "75%" }}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);
