import React from "react";
import { Link } from "react-router-dom";
// @ts-ignore
import AnchorLink from "react-anchor-link-smooth-scroll";
import { PRIMARY_TXT, PRIMARY_BCK } from "../constants/colors";

type NavLink = {
  url?: string;
  extUrl?: string;
  anchor?: string;
  label: string;
  button?: boolean;
};

interface Props {
  mainUrl: string;
  title: string;
  titleShort?: string;
  links: Array<NavLink>;
  logo?: any;
}

export const NavBar = ({
  mainUrl,
  title,
  titleShort = title,
  links,
  logo,
}: Props) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <>
      <div className="w3-top">
        <div
          className="w3-bar w3-card"
          id="myNavbar"
          style={{ backgroundColor: PRIMARY_BCK }}
        >
          <Link
            to={mainUrl}
            className="w3-bar-item w3-button w3-wide w3-hide-small"
            style={{ color: PRIMARY_TXT }}
          >
            {title}
          </Link>
          <a
            href={mainUrl}
            className="w3-bar-item w3-button w3-wide w3-hide-medium w3-hide-large"
            style={{ color: PRIMARY_TXT }}
          >
            {titleShort}
          </a>
          {logo && (
            <img
              // className=" w3-button"
              // className='w3-image'
              src={logo}
              alt="Docteur Florence GHION"
              style={{
                width: "50px", //, borderWidth:1, borderStyle:'solid'
                paddingTop: 5,
                paddingBottom: 5,
              }}
            />
          )}
          {/* Right-sided navbar links */}
          <div className="w3-right w3-hide-small w3-hide-medium">
            {links.map(({ url, extUrl, label, anchor, button }) => (
              <>
                {url && (
                  <Link to={url} key={url}>
                    <a
                      href="#"
                      className="w3-bar-item w3-button"
                      style={
                        button
                          ? { fontWeight: "bold", color: "darkblue" }
                          : { color: PRIMARY_TXT }
                      }
                    >
                      {label}
                    </a>
                  </Link>
                )}
                {extUrl && (
                  <a
                    href={extUrl}
                    className="w3-bar-item w3-button"
                    style={
                      button
                        ? { fontWeight: "bold", color: "darkblue" }
                        : { color: PRIMARY_TXT }
                    }
                  >
                    {label}
                  </a>
                )}
                {anchor && (
                  <AnchorLink
                    offset="75"
                    href={anchor}
                    className="w3-bar-item w3-button"
                    style={{ color: PRIMARY_TXT }}
                  >
                    {label}
                  </AnchorLink>
                )}
              </>
            ))}
          </div>

          {/* Hide right-floated links on small screens and replace them with a menu icon */}
          {/* TODO */}
          <a
            className="w3-bar-item w3-button w3-right w3-hide-large w3-hide-large"
            onClick={() => setExpanded((s) => !s)}
          >
            <i className="fa fa-bars" />
          </a>
        </div>
      </div>
      {/* Sidebar on small screens when clicking the menu icon */}
      <nav
        className="w3-sidebar w3-bar-block w3-black w3-card w3-animate-left w3-hide-large"
        style={{ display: expanded ? "block" : "none" }}
        id="mySidebar"
      >
        <a
          href="javascript:void(0)"
          onClick={() => setExpanded((s) => !s)}
          className="w3-bar-item w3-button w3-large w3-padding-16"
        >
          Fermer ×
        </a>
        {links.map(({ url, extUrl, label, anchor, button }) => (
          <>
            {url && (
              <Link to={url} key={url}>
                <a
                  href="#"
                  className="w3-bar-item w3-button"
                  onClick={() => setExpanded((s) => !s)}
                >
                  {label}
                </a>
              </Link>
            )}
            {extUrl && (
              <a
                href={extUrl}
                className="w3-bar-item w3-button"
                onClick={() => setExpanded((s) => !s)}
              >
                {label}
              </a>
            )}
            {anchor && (
              <AnchorLink
                onClick={() => setExpanded((s) => !s)}
                offset="75"
                href={anchor}
                className="w3-bar-item w3-button"
              >
                {label}
              </AnchorLink>
            )}
          </>
        ))}
      </nav>
    </>
  );
};
