import React from "react";
import { NavBar } from "../components/NavBar";
import { Header } from "../components/Header";
import { Cards } from "../components/Cards";
// import logo from "../assets/logo-flo-transp.png";
import logo from "../assets/logo_3.png";
import { Route } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import { GeneralInformations } from "./subpages/GeneralInformations";
import { Urgencies } from "./subpages/Urgencies";
import { DMG } from "./subpages/DMG";
import { UsefulLinks } from "./subpages/UsefulLinks";
import { CovidInfo } from "./subpages/CovidInfo";
import { GMaps } from "../components/GMaps";
import { ScrollToTopOnMount } from "../components/ScrollToTop";

export const HomeDoctor = () => (
  <>
    {/*<ScrollToTopOnMount />*/}
    <NavBar
      logo={logo}
      title={"Docteur GHION"}
      mainUrl={"/docteur"}
      links={[
        { label: "Le cabinet", url: "/" },
        { label: "Infos pratiques", url: "/docteur/informations" },
        { label: "Urgences", url: "/docteur/urgences" },
        { label: "Sites utiles", url: "/docteur/liens-utiles" },
        { label: "Infos COVID", url: "/docteur/covid" },
        { label: "DMG", url: "/docteur/dmg" },
        {
          label: "RDV",
          // extUrl:
          //   "https://progenda.be/calendars/docteur-ghion-florence-medecin-generaliste-schaerbeek",
          extUrl:
            "https://www.doctoranytime.be/fr/d/general-practitioner/florence-ghion",
          // button: true,
        },
        { label: "Kiné", url: "/kine" }
      ]}
    />
    <Header />
    <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      atActive={{ opacity: 1 }}
      className="switch-wrapper"
    >
      <Route exact path={"/docteur"}>
        <ScrollToTopOnMount />
        <Cards />
        <GMaps greyed={true} />
        <div
          style={{ textAlign: "center", color: "darkgrey", marginBottom: 20 }}
        >
          2021 - Webapp{" "}
          <a target="_blank" href="https://www.linkedin.com/in/ludovic-gustin/">
            {" "}
            by LG
          </a>
        </div>
      </Route>
      <Route path="/docteur/informations">
        <GeneralInformations />
      </Route>
      <Route path="/docteur/urgences">
        <Urgencies />
      </Route>
      <Route path="/docteur/dmg">
        <DMG />
      </Route>
      <Route path="/docteur/liens-utiles">
        <UsefulLinks />
      </Route>
      <Route path="/docteur/covid">
        <CovidInfo />
      </Route>
    </AnimatedSwitch>
    {/*<CMSList />*/}
  </>
);
