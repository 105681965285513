import React from "react";
import { Team } from "../components/Team";
import { NavBar } from "../components/NavBar";
import { GMaps } from "../components/GMaps";
import { Vision } from "../components/Vision";
import { CMSProjects } from "../components/CMSProjects";
import { SousTeam } from "../components/SousTeam";
import { ScrollToTopOnMount } from "../components/ScrollToTop";

export const Landing = () => (
  <>
    <ScrollToTopOnMount />
    <NavBar
      title={"CABINET 34 SCHAERBEEK"}
      titleShort={"CABINET 34"}
      mainUrl={"/"}
      links={[
        { label: "Docteur", url: "/docteur" },
        {
          label: "Kinésithérapeute",
          url: "/kine",
        },
        { label: "Equipe", anchor: "#team" },
        { label: "Vision", anchor: "#vision" },
        { label: "Projets", anchor: "#projects" },
        { label: "Contact", anchor: "#map" },
      ]}
    />
    <Team />
    <SousTeam />
    <Vision />
    <br />
    <CMSProjects />
    <GMaps greyed={false} />
    <div style={{ textAlign: "center", color: "darkgrey", marginBottom: 20 }}>
      2021 - Webapp{" "}
      <a target="_blank" href="https://www.linkedin.com/in/ludovic-gustin/">
        {" "}
        by LG
      </a>
    </div>
  </>
);
