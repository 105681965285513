import React from "react";
import { ServicesHeader } from "../../components/ServicesHeader";
import { AUrl } from "../../components/AUrl";

export const UsefulLinks = () => (
  <div
    id={'top'}
    className="w3-content w3-container w3-padding-64"
    style={{ textAlign: "justify" }}
  >
    <ServicesHeader title={"Liens utiles"} />
    <i>Internet est un outil extraordinaire, s’il est bien utilisé.</i>
    <br />
    <i>
      Informez-vous surtout, ne croyez pas tout. Discutez-en, et surtout
      continuez à vous informer.
    </i>
    <h3>Quelques liens peuvent vous être utiles :</h3>

    <h4>Médicaments et maladies</h4>
    <AUrl url="http://www.mongeneraliste.be/maladies" />
    <AUrl url="https://www.diabete.be/#gsc.tab=0" />
    <AUrl url="https://www.saintluc.be/documentation/index.php" />
    <AUrl url="https://anorevie.be/" />
    <AUrl url="https://kce.fgov.be/fr" />
    <AUrl url="https://www.cbip.be/fr/start" />
    <h4>Enfants</h4>
    <AUrl url="https://www.one.be/public/" />
    <AUrl url="https://www.vaccination-info.be/faq/" />
    <h4>Pour arrêter de fumer :</h4>
    <AUrl url="https://www.tabacstop.be/" />
    <h4>Vaccination et voyage</h4>
    <AUrl url="https://www.itg.be/" />
    <AUrl url="https://www.vacciweb.be/home" />
    <h4>Cancer</h4>
    <AUrl url="https://www.bruprev.be/fr/bruprev" />
    <AUrl url="https://www.cancer.be/" />
    <h4>Soins palliatifs</h4>
    <AUrl url="http://www.soinspalliatifs.be/les-soins-palliatifs-en-belgique.html" />
    <h4>Coronavirus:</h4>
    <AUrl url="https://www.info-coronavirus.be/" />
    <AUrl url="https://covid-19.sciensano.be/fr" />
    <AUrl url="https://testcovid.be/" />
    <AUrl url="https://diplomatie.belgium.be/fr/Services/voyager_a_letranger" />
    <h4>Sport</h4>
    <AUrl url="https://www.conseils-courseapied.com/" />
  </div>
);
