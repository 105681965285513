import React from "react";
import "./App.css";
import { HomeDoctor } from "./screens/HomeDoctor";
import { Landing } from "./screens/Landing";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import { HomeKine } from "./screens/HomeKine";
import ScrollToTop from "./components/ScrollToTop";
function App() {
  return (
    <div className="App">
      <Router>
        {/*<ScrollToTop/>*/}
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          <Route path="/docteur">
            <HomeDoctor />
          </Route>
          <Route path="/kine">
            <HomeKine />
          </Route>
          <Route path="/">
            <Landing />
          </Route>
        </AnimatedSwitch>
      </Router>
    </div>
  );
}

export default App;
