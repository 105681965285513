import React from "react";
import { Link } from "react-router-dom";

export const GMaps = ({ greyed }: { greyed: boolean }) => (
  <div id="map" className={"w3-container w3-margin-bottom "}>
    <div
      className={
        "w3-row-padding w3-padding-64 " + (greyed ? "w3-light-grey" : "")
      }
      // style={{backgroundColor: greyed ? 'lightblue' : 'white'}}
    >
      <div className="w3-content">
        <h1>Contact</h1>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexWrap: "wrap",
            flexDirection: "row",
            marginTop: "25px",
            // marginBottom: "25px",
          }}
        >
          <div
            style={{
              flex: 1,
              borderStyle: "solid",
              borderWidth: "1px 1px 1px 1px",
              padding: 10,
              textAlign: "center",
            }}
          >
            <h3>Docteur Florence GHION</h3>
            <p className="w3-opacity">
              <a
                className="w3-large"
                href="mailto:florence@ghion.be"
                style={{
                  color: "inherit",
                  textDecoration: "inherit",
                }}
              >
                florence@ghion.be
              </a>
            </p>
            <p className="w3-opacity">
              <a
                className="w3-large"
                href="tel:+32474790169"
                style={{
                  // color: "inherit",
                  // textDecoration: "inherit",
                  fontWeight: "bolder",
                }}
              >
                0474/79.01.69
              </a>
            </p>
            <a
              className="w3-large"
              href={
                "https://www.doctoranytime.be/fr/d/general-practitioner/florence-ghion"
              }
              target={"_blank"}
              rel={"noreferrer"}
            >
              Prendre rendez-vous en ligne
            </a>
          </div>
          <div
            style={{
              flex: 1,
              borderStyle: "solid",
              borderWidth: "1px",
              padding: 10,
              textAlign: "center",
            }}
          >
            <h3>Bénédicte GHION</h3>
            <p className="w3-opacity">
              <a
                className="w3-large"
                href="mailto:benedicte@ghion.be"
                style={{
                  color: "inherit",
                  textDecoration: "inherit",
                }}
              >
                benedicte@ghion.be
              </a>
            </p>
            <p className="w3-opacity">
              <a
                className="w3-large"
                href="tel:+32478839256"
                style={{
                  // color: "inherit",
                  // textDecoration: "inherit",
                  fontWeight: "bolder",
                }}
              >
                0478/83.92.56
              </a>
            </p>
            <a
              className="w3-large"
              href={
                "https://www.doctoranytime.be/d/kinesitherapeute/benedicte-ghion"
              }
              target={"_blank"}
              rel={"noreferrer"}
            >
              Prendre rendez-vous en ligne
            </a>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            borderStyle: "solid",
            borderWidth: "0px 1px 1px 1px",
            // marginTop: "25px",
            marginBottom: "25px",
          }}
        >
          <div
            className="w3-large"
            style={{
              flex: 1,
              padding: 10,
              textAlign: "center",
            }}
          >
            Rue Aimé Smekens 34, 1030 Schaerbeek
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2571.6191472745104!2d4.4045064251038495!3d50.84887742325471!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x865c165a59969bee!2sDr%20Florence%20Ghion%20(M%C3%A9decin%20g%C3%A9n%C3%A9raliste%20-%20Woluw%C3%A9%20et%20Schaerbeek!5e0!3m2!1sen!2sbe!4v1612650261312!5m2!1sen!2sbe"
          // src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d157.4395867140149!2d4.4070605!3d50.849066!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x865c165a59969bee!2sDr%20Florence%20Ghion%20(M%C3%A9decin%20g%C3%A9n%C3%A9raliste%20-%20Woluw%C3%A9%20et%20Schaerbeek!5e0!3m2!1sen!2sbe!4v1612649318277!5m2!1sen!2sbe"
          width="100%"
          height="400px"
          frameBorder="0"
          style={{ display: "block", border: 0, margin: "0 auto" }}
          allowFullScreen={false}
          aria-hidden="false"
          tabIndex={0}
        />
      </div>
    </div>
  </div>
);
