import React from "react";
import teamJ from "../../assets/bene.jpg";
import { PRIMARY_TXT, PRIMARY_BCK } from "../../constants/colors";
import logoBene from "../../assets/logo-bene.jpeg";

export const KineInformations = () => (
  <div
    className="w3-content w3-container w3-padding-64"
    style={{ textAlign: "justify" }}
  >
    <div
      style={{
        width: "50%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "50px",
        marginBottom: "50px",
      }}
    >
      <a
        href="https://www.doctoranytime.be/d/kinesitherapeute/benedicte-ghion"
        style={{ color: "inherit", textDecoration: "inherit" }}
      >
        <div
          className="w3-card w3-center"
          style={{ backgroundColor: PRIMARY_BCK, height: "100%" }}
        >
          <img src={teamJ} alt="Bénédicte GHION" style={{ width: "100%"}} />
          <div className="w3-container" style={{ color: PRIMARY_TXT }}>
            <h3>Bénédicte GHION</h3>
            <p className="w3-opacity">Kinésithérapie</p>
          </div>
        </div>
      </a>
    </div>
    <h3>Ma pratique</h3>
    <p>
      Kinésithérapeute dynamique et à l’écoute, j'aime prendre soin des patients
      dans leur globalité. Je suis à l’écoute de mes patients afin de réfléchir
      ensemble à la meilleure manière de travailler en fonction des
      possibilités, des envies et des objectifs.
    </p>
    <div
      style={{
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <img
        src={logoBene}
        alt="Docteur Florence GHION"
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: "50%", //, borderWidth:1, borderStyle:'solid'
          height: "50%", //, borderWidth:1, borderStyle:'solid'
        }}
      />
    </div>
    <p>
      Je fais de la <b>kinésithérapie générale</b> (troubles musculaires,
      respiratoires, ostéo-articulaires, cardio-vasculaires, revalidation
      générale), la <b>postnatale</b> et je suis spécialisée en
      <b> revalidation neurologique</b>. Je travaille pour les <b>adultes </b>
      comme pour les <b>enfants</b> et les <b>bébés</b>.
    </p>
    <p>
      Très habituée au monde du handicap depuis mon plus jeune âge, je prends
      beaucoup en charge des enfants et adultes en situation de handicap. Je
      base mon travail en grande partie sur la méthode HABIT-ILE dans laquelle
      je suis formée qui s’appuie sur des principes
      <b> d’apprentissage moteur</b> et qui utilise des objectifs des activités
      fonctionnelles déterminées par le patient et son entourage. Pour les
      enfants, je fais cela un maximum sous forme de jeu afin que l’enfant ne se
      rende pas compte qu’il est en thérapie.
    </p>
    <p>
      Je travaille dans deux cabinets, le premier, en collaboration avec ma
      sœur, le Dr Florence Ghion, médecin généraliste et ma tante, Geneviève van
      Caubergh, kinésithérapeute également. Le deuxième, au Centre Médical des
      Nations.
    </p>
    <h3>Langues</h3>
    <p>Je parle couramment le français, le néérlandais et l’anglais.</p>
    <h3>Adresses</h3>
    <p>
      <ul>
        <li>Rue Aimé Smekens 34 – 1030 Bruxelles</li>
        <li> Avenue Franklin Roosevelt 127 – 1050 Bruxelles</li>
      </ul>
    </p>
    <h3>Consultation</h3>
    Les consultations durent 30 minutes. Vous pouvez prendre un rendez-vous en
    ligne via le site de DrAnytime :
    <br />
    <a
      href={"https://www.doctoranytime.be/d/kinesitherapeute/benedicte-ghion"}
      target={"_blank"}
      rel={"noreferrer"}
    >
      Prendre rendez-vous en ligne
    </a>
    <h3>Contact</h3>
    <div
      style={{
        flex: 1,
        borderStyle: "solid",
        borderWidth: "1px",
        padding: 10,
        textAlign: "center",
      }}
    >
      <h3>Bénédicte GHION</h3>
      <p className="w3-opacity">
        <a
          href="mailto:benedicte@ghion.be"
          style={{
            color: "inherit",
            textDecoration: "inherit",
          }}
        >
          benedicte@ghion.be
        </a>
      </p>
      <p className="w3-opacity">
        <a
          href="tel:+32478839256"
          style={{
            color: "inherit",
            textDecoration: "inherit",
          }}
        >
          0478/83.92.56
        </a>
      </p>
      <a
        href={"https://www.doctoranytime.be/d/kinesitherapeute/benedicte-ghion"}
        target={"_blank"}
        rel={"noreferrer"}
      >
        Prendre rendez-vous en ligne
      </a>
    </div>
  </div>
);
